<template>
  <validation-provider
    ref="validation"
    v-slot="validation"
    class="form-validation-field"
    :mode="mode"
    :rules="rules"
    :name="readableName"
    :vid="vid || name"
    v-bind="$attrs.validation"
  >
    <!-- Input group :state="null" needed for FormValidationDatePicker.vue  -->
    <b-form-group
      :label="label"
      :label-for="name"
      :state="validation.failed || null"
      :invalid-feedback="validation.errors[0]"
    >
      <slot name="prepend-form-group" />

      <slot name="label" />

      <!-- Input Wrapper -->
      <component
        :is="wrapper"
        :class="[invalidClass(validation.failed), wrapperClass]"
      >
        <slot name="prepend-input" />

        <!-- Field -->
        <slot
          name="field"
          :validation="validation"
        >
          <component
            :is="component"
            :id="vid || name"
            ref="field"
            :value="value"
            :name="name"
            :placeholder="placeholder"
            :class="[invalidClass(validation.failed), inputClass]"
            v-bind="$attrs"
            v-on="$listeners"
          >
            <!-- Pass on all named slots -->
            <slot
              v-for="slot in Object.keys($slots)"
              :slot="slot"
              :name="slot"
            />

            <!-- Pass on all scoped slots -->
            <template
              v-for="slot in Object.keys($scopedSlots)"
              :slot="slot"
              slot-scope="scope"
            >
              <slot
                :name="slot"
                v-bind="scope"
              />
            </template>
          </component>
        </slot>
        <!-- /Input -->

        <slot name="append-input" />
      </component>
      <!-- /Wrapper -->

      <slot name="append-form-group" />
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormSelect, BInputGroup, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { VueAutosuggest } from 'vue-autosuggest'
import flatPickr from 'vue-flatpickr-component'

import { required, max, isNot } from '@core/utils/validations/validations'

export default {
  components: {
    BFormInput,
    BFormSelect,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    flatPickr,
    VueAutosuggest,
    ValidationProvider,
    // Rules
    required,
    max,
    isNot,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      required: true,
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    vid: {
      type: String,
      default: undefined,
    },
    mode: {
      type: String,
      default: undefined,
    },
    component: {
      type: String,
      default: 'BFormInput',
    },
    inputClass: {
      type: [String, Object],
      default: '',
    },
    wrapper: {
      type: String,
      default: 'span',
    },
    wrapperClass: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    readableName() {
      return this.name.replace(/[-_]/g, ' ') // Remove underscores and dashes from input name.
    },
  },
  methods: {
    invalidClass(failed) {
      return { 'is-invalid': failed }
    },
  },
}
</script>
